import axios from 'axios';

export const axiosConfig = () => {
	axios.interceptors.request.use((config) => {
		config.headers['X-CSRF-TOKEN'] = getCSRFHeader();
		return config;
	});

	axios.defaults.headers.common.Accept = 'application/json';
};

export function getCSRFHeader() {
	const csrfToken = document.cookie.replace(
		/(?:(?:^|.*;\s*)csrf_token\s*\=\s*([^;]*).*$)|^.*$/,
		'$1'
	);

	return decodeURIComponent(csrfToken);
}
