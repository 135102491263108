import ReactRailsUJS from 'react_ujs';

let allImports = {};

/**
 * Takes an `import.meta.glob("/glob/to/components", { eager: true })` value (an eager glob import) and initializes
 * React-Rails with the component files returned by that eager glob import.
 *
 * @param importMetaGlob - an `import.meta.glob(..., { eager: true })` value, **not** a glob string.
 */
export function initializeReactRails(importMetaGlob: Record<string, any>) {
	allImports = { ...allImports, ...importMetaGlob };
	ReactRailsUJS.getConstructor = viteConstructorRequireContext(allImports);
}

/**
 * Custom constructor in order to make Vite glob imports work with React-Rails-UJS;
 * @see {@link https://github.com/reactjs/react-rails/issues/1134#issuecomment-1415112288}
 */
function viteConstructorRequireContext(reqCtx) {
	const fromRequireContext = function (reqCtx) {
		return function (className) {
			const parts = className.split('.');
			const filename = parts.shift();
			const keys = parts;
			// Load the module:
			const componentPath = Object.keys(reqCtx).find((path) => path.search(filename) > 0)!;
			let component = reqCtx[componentPath];
			// Then access each key:
			keys.forEach(function (k) {
				component = component[k];
			});
			component = component.default;
			return component;
		};
	};

	const fromCtx = fromRequireContext(reqCtx);
	return function (className) {
		let component;
		try {
			// `require` will raise an error if this className isn't found:
			component = fromCtx(className);
		} catch (firstErr) {
			console.error(firstErr);
		}
		return component;
	};
}
